const BenefitsList = [
  {
    title: 'Artificial Intelligence chat bot that is trained on everything related to biologics and biosimilars',
    image: 'https://images.ctfassets.net/ohqd46y4hkj2/764FPyLRGoPl29r84QnSaZ/07ad11bf9c15c071d6c2f943248d0ed7/4.svg',
  },
  {
    title: 'Can be used to answer any and all questions regarding biologics and biosimilars',
    image: 'https://images.ctfassets.net/ohqd46y4hkj2/28iSVyvROKwXiWwZ97htEp/517b0d72f79005b4d52aa200f56b16af/5.svg',
  },
  {
    title:
      'A powerful, cloud based tool that uses a sophisticated AI enabled machine learning language model to harness important information rapidly, securely and accurately.',
    image: 'https://images.ctfassets.net/ohqd46y4hkj2/104iQtpH9m3sl339cgVaWv/08ac7051c5a1a56de661f749354501fc/6.svg',
  },
];

export default BenefitsList;
