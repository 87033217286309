import { useNavigate } from 'react-router-dom';

import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';

export default function BiosimilarsBenefits({ list, title, color }) {
  const isDesktop = useResponsive('up', 'md');
  const navigate = useNavigate();

  return (
    <Box>
      <Container>
        <Typography
          variant="h3"
          className="text-center"
          sx={{ textAlign: 'center', color: color === 'black' ? 'black' : 'white' }}
        >
          {title}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}
          className="flex justify-center"
        >
          {list &&
            list.length >= 1 &&
            list.map((item, index) => (
              <Grid key={index} item md={3} container className="card-hover-effect">
                <Card
                  sx={{
                    '&:hover': {
                      color: '#FFF',
                    },
                  }}
                >
                  <CardContent>
                    <Box>
                      <img src={item.image} alt={item.title} width={isDesktop ? '30%' : '20%'} />
                    </Box>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      {item.title}
                    </Typography>
                    {item.subTitle ? (
                      <>
                        <Typography sx={{ mt: 2 }}>{item.subTitle}</Typography>
                      </>
                    ) : (
                      <></>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
        <Box sx={{ mt: 10, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="container"
            size="large"
            sx={{
              backgroundColor: color === 'black' ? '#1b6f83' : 'white',
              fontSize: '18px',
              color: color === 'black' ? 'white' : 'black',
              '&:hover': { backgroundColor: color === 'black' ? '#0d5263' : '#dfdfdf' },
            }}
            onClick={(e) => navigate('/demo')}
          >
            Request a Demo Today
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
