const cleanText = (text) => {
  return text.replace(/\s{2,}|\t|\n/g, ' ').trim();
};

const chunkTextInSentences = (text, maxChunkSize) => {
  const sentences = text.split('.');
  const chunks = [];
  let currentChunk = '';

  for (let i = 0; i < sentences.length; i += 1) {
    const sentence = sentences[i];
    if (currentChunk.length + sentence.length + 1 <= maxChunkSize) {
      currentChunk += sentence.concat('.');
    } else {
      chunks.push(currentChunk);
      currentChunk = sentence.concat('.');
    }
  }

  if (currentChunk) chunks.push(currentChunk);

  return chunks;
};

export const processTextFile = async (file, charSize) => {
  try {
    const result = await readAndProcessTextFile(file, charSize);
    return result;
  } catch (error) {
    console.error('Error processing the text file:', error);
    return { success: false, file, error: 'Error processing the text file' };
  }
};

async function readAndProcessTextFile(file, charSize) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      try {
        const text = event.target.result;
        const cleanedText = cleanText(text);
        const chunks = chunkTextInSentences(cleanedText, charSize);
        resolve({ success: true, file, chunks });
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = function (event) {
      reject(event.target.error);
    };
    reader.readAsText(file);
  });
}
