import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import CustomAppBar from '../../layouts/basic/CustomAppbar';
import Questions from '../dashboard/helpers/Questions';
import { withNoAuth } from '../../components/HOC/withNoAuth';
import Footer from '../../components/Footer';
import BenefitsList from '../../assets/data/BenefitsList';
import BCMASadvertisement from '../home/helpers/BCMASadvertisement';

function QuestionsAnswersPage() {
  const isDesktop = useResponsive('up', 'md');
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>FAQs</title>
        <meta property="og:title" content="Biosimilars AI Questions" />
        <meta
          property="og:description"
          content="Explore the Biosimilars AI FAQs page to discover how our advanced AI-powered tool revolutionizes medical affairs. Our sophisticated machine learning model, trained on an extensive collection of medical data, streamlines your processes. Uncover insights about custom training on your internal materials such as clinical studies, SOPs, and articles. Say goodbye to the hassle of searching through scattered folders—experience efficient, data-driven solutions"
        />
        <meta
          property="og:image"
          content="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/biologics-ai/BiosimilarsAI.png"
        />
        <meta property="og:url" content="https://biosimilarsai.org/questions" />
      </Helmet>
      <Box
        sx={{
          m: isDesktop ? 2 : 0,
          borderRadius: isDesktop ? '15px' : '0px',
          pl: isDesktop ? 12 : 2,
          pr: isDesktop ? 12 : 2,
          height: isDesktop ? '600px' : '500px',
          background: `linear-gradient(45deg, #014459 0%, #000000 100%), radial-gradient(100% 225% at 100% 0%, #014459 0%, #000000 100%), linear-gradient(225deg, #707070 0%, #000000 100%), linear-gradient(135deg, #CDFFEB 10%, #CDFFEB 35%, #009F9D 35%, #ffffff 60%, #07456F 60%, #07456F 67%, #0F0A3C 67%, #0F0A3C 100%)`,
          backgroundSize: 'cover',
        }}
      >
        <CustomAppBar />
        <Container maxWidth="" sx={{ padding: '0px' }}>
          <Box
            sx={{
              pt: 9,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ width: '100%' }} variant="h1" className="animate-charcter">
              FAQs
            </Typography>
            <Typography variant="h4" sx={{ pt: 3, color: 'white', width: '75%', fontWeight: 500 }}>
              Frequently Asked Questions about MedAffairs AI{' '}
            </Typography>

            <Box sx={{ mt: 3 }}>
              <Button
                sx={{
                  backgroundColor: 'white',
                  fontSize: '18px',
                  color: 'black',
                  '&:hover': { backgroundColor: '#dfdfdf' },
                  mr: 2,
                }}
                fullWidth={!isDesktop}
                size="large"
                onClick={(e) => navigate('/demo')}
              >
                Request a Demo
              </Button>

              <Button
                component={Link}
                to="/login"
                fullWidth={!isDesktop}
                sx={{
                  backgroundColor: 'white',
                  fontSize: '18px',
                  color: 'black',
                  '&:hover': { backgroundColor: '#dfdfdf' },
                  mt: isDesktop ? 0 : 2,
                }}
                size="large"
              >
                Get Started Today
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container>
        <Box sx={{ pt: 10, pb: 10 }}>
          <Questions />
        </Box>

        <Box>
          <BCMASadvertisement />
        </Box>
      </Container>

      <Footer />
    </>
  );
}

export default withNoAuth(QuestionsAnswersPage);
