export const UN_AUTH_API = 'https://8sgivbt6w9.execute-api.us-east-1.amazonaws.com/default';
export const MAIN_API = 'https://9cp2i5q2fl.execute-api.us-east-1.amazonaws.com/default';
export const UPLOAD_DOCUMENT_API = 'https://9i4asj8qr0.execute-api.us-east-1.amazonaws.com/default';
export const COMPLIANCE_API = 'https://b4o1jcgola.execute-api.us-east-1.amazonaws.com/default/';

export const UN_AUTH_USER = UN_AUTH_API.concat('/user');
export const GET_AUTH_USER_API = MAIN_API.concat('/user');
export const SEARCH_API = MAIN_API.concat('/search');
export const TRAIN_API = MAIN_API.concat('/train');
export const UPLOAD_DOCUMENT_TO_EXTERNAL_DB_API = MAIN_API.concat('/documents');
export const GET_DOCUMENT_FROM_EXTERNAL_DB_API = MAIN_API.concat('/documents');
export const DELETE_DOCUMENTS_FROM_EXTERNAL_DB_API = MAIN_API.concat('/documents');

export const GET_DOCUMENT_FROM_EXTERNAL_DB_API_FILTERED = MAIN_API.concat('/documents/filtered');
export const GET_DOCUMENT_UPLOAD_URL = UPLOAD_DOCUMENT_API.concat('/upload-url');
export const DOWNLOAD_FILE = UPLOAD_DOCUMENT_API.concat('/download');
export const COMPANY_USERS_API = MAIN_API.concat('/company-users');
export const DELETE_S3_DOCUMENT_API = UPLOAD_DOCUMENT_API.concat('/delete-documents');
export const UPDATE_AUTH_USER_API = MAIN_API.concat('/user');

export const GET_POLICY_REQUIREMENTS = COMPLIANCE_API.concat('/requirements');
