import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// components
import { Auth } from 'aws-amplify';
import Iconify from '../../../components/iconify';
import { loginValidate } from './loginValidate';
import { signInUser } from './signInUser';
import { newPasswordValidate } from './newPasswordValidate';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setname] = useState('');
  const [loading, setLoading] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const [resetPassword, setResetPassword] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    const err = loginValidate(email, password);
    if (!err.message) {
      const result = await signInUser(email, password, dispatch);
      if (result && result.success === true) {
        setLoading(false);
        if (result.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setResetPassword(true);
        } else {
          navigate('/app');
        }
      } else {
        setLoading(false);
        toast.error(result.error.message || 'Error loggin in! Please try again');
      }
    } else {
      console.log('Error exists');
      toast.error(err.message);
    }
  };

  const resetPasswordHandler = async () => {
    const err = newPasswordValidate(name, newPassword, newPasswordConfirm);
    if (!err.message) {
      const result = await signInUser(email, password, dispatch);
      console.log(result);
      if (result && result.success === true) {
        if (result.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const loggedUser = await Auth.completeNewPassword(
            result.user, // the Cognito User Object
            newPassword, // the new password
            {
              name,
            }
          );

          console.log(loggedUser);
          navigate('/app');
          toast.success('Password successfully changed!');
        }
      } else {
        toast.error(result.error.message || 'Error loggin in! Please try again');
      }
    } else {
      toast.error(err.message);
    }
  };

  return !resetPassword ? (
    <>
      <Box>
        <Button component={Link} to="/">
          <KeyboardBackspaceIcon style={{ marginRight: '8px' }} />
          Back
        </Button>
      </Box>
      <Typography variant="h4" gutterBottom>
        Biosimilars AI
      </Typography>
      <Typography variant="h7" sx={{ mb: 3 }} gutterBottom>
        Real-time Biologics & Biosimilars Insights When You Need It{' '}
      </Typography>

      <Typography variant="body2" sx={{ mb: 2 }}>
        Don’t have an account? {''}
        <Link to="/demo" variant="subtitle2">
          Contact us to get started
        </Link>
      </Typography>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Button component={Link} to="/forgot-password">
          Forgot Password?
        </Button>
      </Stack>
      <LoadingButton
        loading={loading}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        sx={{
          backgroundColor: '#021f28',
        }}
      >
        Login
      </LoadingButton>
    </>
  ) : (
    <>
      <Typography variant="h4" gutterBottom>
        <LockIcon /> Password Reset Required
      </Typography>
      <Typography variant="body2" sx={{ mb: 5 }}>
        Create a password that is both strong and easy to remember
      </Typography>
      <form noValidate>
        <Stack spacing={3}>
          <TextField
            inputProps={{
              autoComplete: 'off',
            }}
            name="full name"
            label="Full Name"
            onChange={(e) => setname(e.target.value)}
          />

          <TextField
            name="password"
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              autoComplete: 'new-password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="password"
            label="New Password Confirm"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
            InputProps={{
              autoComplete: 'new-password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </form>
      <LoadingButton
        sx={{ mt: 3 }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={resetPasswordHandler}
      >
        Change Password
      </LoadingButton>{' '}
    </>
  );
}
