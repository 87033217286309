import { Box, Button, Card, CardContent, Container, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import Tab4 from './Tab4';
import Tab5 from './Tab5';
import useResponsive from '../../../hooks/useResponsive';
import VideoLoopComponent from './VideoLoopComponent';

export default function DemoVideos() {
  const [tabValue, setTabValue] = useState('Internal Data Search');
  const [value, setValue] = useState(0);
  const isDesktop = useResponsive('up', 'md');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box>
        <Typography variant="h3">Enhance Your Biosimilars knowledge with a Fast & Secure AI Tool</Typography>
        <Typography>
          Our AI tool is trained on everything you need to know in biologics and biosimilars, offering unparalleled
          customization by incorporating your specific data, such as clinical insights, SOPs, guidances, etc.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <VideoLoopComponent
            url={'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/biologics-ai/biosimilars-video.mp4'}
          />
        </Box>
      </Box>
    </>
  );
}
