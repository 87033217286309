import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import toast from 'react-hot-toast';
import { useState } from 'react';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CancelIcon from '@mui/icons-material/Cancel';
import FileTypeImage3D from '../../../../helpers/FileTypeImage3D';
import { formatString } from '../../../../helpers/formatString';
import { processTextFile } from '../../../../helpers/chunking/processTextFile';
import { processPdfFile } from '../../../../helpers/chunking/processPdfFile';
import { processImageFile } from '../../../../helpers/chunking/processImageFile';
import { processExcelFile } from '../../../../helpers/chunking/processExcelFile';
import { getRequirementsFromComplianceDoc } from '../../../../store/actions/compliance/policy';

export default function CreateNewComplianceDocument({ setPage }) {
  const [open, setOpen] = useState(false);
  const maxSizeInBytes = 10 * 1024 * 1024; // 10MB in bytes
  const [selectedFile, setSelectedFile] = useState(null);
  const [chunkedOutput, setChunkedOutputs] = useState([]);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function processSingleFile(file) {
    if (file.name.endsWith('.txt')) {
      const textFileOutput = await processTextFile(file, 3500);
      console.log('textFileOutput', textFileOutput);
      return textFileOutput;
    }
    if (file.name.endsWith('.pdf')) {
      const pdfFileOutput = await processPdfFile(file, 3500);
      return pdfFileOutput;
    }
    if (file.name.endsWith('.docx') || file.name.endsWith('.doc')) {
      console.log('Docx File');
      return null;
    }
    if (file.name.endsWith('.png') || file.name.endsWith('.jpg')) {
      const imgFileOutput = await processImageFile(file, 3500);
      return imgFileOutput;
    }
    if (file.name.endsWith('.csv') || file.name.endsWith('.xls')) {
      const excelFileOutput = await processExcelFile(file, 3500);
      console.log('excel file output', excelFileOutput);
      return excelFileOutput;
    }
    toast.error('Unsupported file type uploaded.');
    return null;
  }

  const handleFileSelect = async (event) => {
    const inValidFile = event.target.files[0].size > maxSizeInBytes;
    if (inValidFile) {
      toast.error('File size too large! Please keep it less than 10MB');
    } else {
      const file = event.target.files[0];
      if (file) {
        const output = await processSingleFile(file);
        if (output !== null && output.success === true) {
          setChunkedOutputs((prevOutputs) => {
            const newOutputs = [...prevOutputs, output].slice(-3);
            return newOutputs;
          });
        }
      }
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleRemove = () => {
    setSelectedFile(null);
    setChunkedOutputs([]);
  };

  const gatherRequirements = () => {
    console.log('Gather requirements');
    console.log('chunkedOutput', chunkedOutput);
    const foundChunks = chunkedOutput[0].chunks;
    const totalOutputLengthFromOpenAI = Math.floor(1000 / foundChunks.length);
    console.log('total Output Length From OpenAI: ', totalOutputLengthFromOpenAI);
    dispatch(getRequirementsFromComplianceDoc(foundChunks, totalOutputLengthFromOpenAI));
  };

  return (
    <div>
      <Box className="flex align-center">
        <IconButton onClick={(e) => setPage('checker')}>
          <ArrowCircleLeftIcon style={{ fontSize: '30px' }} />
        </IconButton>
        <Typography variant="h6">back</Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3} className="flex justify-center">
          <Grid item md={selectedFile ? 12 : 10}>
            <Card>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Compliance Policy Name
                </Typography>
                <TextField fullWidth id="outlined-basic" label="Policy Title" variant="outlined" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={selectedFile ? 6 : 10} sx={{ mb: 10 }} container>
            <Card sx={{ width: '100%' }}>
              <CardContent>
                <Typography variant="h6">Compliance Document</Typography>
                <Typography>Upload a new compliance document</Typography>
                <Box sx={{ mt: 3 }}>
                  <label htmlFor="raised-button-file">
                    <input
                      accept=".pdf, .txt, .docx, .png, .jpg, .csv, .xls"
                      style={{ display: 'none' }}
                      id="raised-button-file"
                      type="file"
                      onChange={handleFileSelect}
                    />
                    <Button
                      variant="raised"
                      component="span"
                      className="flex justify-center align-center"
                      sx={{
                        width: '100%',
                        backgroundColor: '#f7f7f7',
                        p: 4,
                        border: 'dashed 2.3px black',
                        borderRadius: '12px',
                      }}
                    >
                      <Box className="flex column justify-center align-center">
                        <img
                          src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/file-upload-v2.svg"
                          alt="file-upload-img"
                        />
                        <Typography sx={{ mt: 2 }} variant="h6">
                          Click Here to Drop your Compliance Document
                        </Typography>
                        <Typography sx={{ mt: 1, color: 'gray' }}>
                          Uploaded documents are private and can only be seen by you
                        </Typography>
                      </Box>
                    </Button>
                  </label>
                </Box>

                {selectedFile ? (
                  <>
                    <Box sx={{ mt: 4 }}>
                      <Typography variant="h6">Uploaded File</Typography>
                      <Card>
                        <CardContent className="flex justify-between align-center">
                          <Box className="flex align-center">
                            <FileTypeImage3D name={selectedFile.name} customWidth={'500px'} />
                            <Typography sx={{ ml: 1 }}>{formatString(selectedFile.name, 20)}</Typography>
                          </Box>
                          <Box>
                            <IconButton onClick={handleRemove}>
                              <CancelIcon />
                            </IconButton>
                          </Box>
                        </CardContent>
                      </Card>

                      <Box sx={{ mt: 3 }} className="flex justify-end">
                        <Button variant="contained" size="large" onClick={gatherRequirements}>
                          Gather all Requirements
                        </Button>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={selectedFile ? 6 : 0} sx={{ mb: 10, display: selectedFile ? 'block' : 'none' }} container>
            <Card sx={{ width: '100%' }}>
              <CardContent>
                <Typography variant="h6">Compliance Requirements</Typography>
                <Typography>Please ensure all requirements necessary for document to be compliant are below</Typography>
                <Box sx={{ mt: 3 }}>
                  <TextField
                    sx={{}}
                    disabled
                    fullWidth
                    placeholder="Compliance Requirements"
                    multiline
                    rows={15}
                    maxRows={15}
                  />
                </Box>
                <Box sx={{ mt: 2, width: '100%' }} className="flex justify-end">
                  <Button variant="contained" disabled>
                    Confirm and Save
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Upload New Compliance Document'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please upload your compliance document for comparison with other documents to determine their compliance
            status.
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <TextField fullWidth id="outlined-basic" label="Document Title" variant="outlined" />
            <Box sx={{ mt: 3 }}>
              <label htmlFor="raised-button-file">
                <input
                  accept=".pdf, .txt, .docx, .png, .jpg, .csv, .xls"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleFileSelect}
                />
                <Button
                  variant="raised"
                  component="span"
                  className="flex justify-center align-center"
                  sx={{
                    width: '100%',
                    backgroundColor: '#f7f7f7',
                    p: 4,
                    border: 'dashed 2.3px black',
                    borderRadius: '12px',
                  }}
                >
                  <Box className="flex column justify-center align-center">
                    <img
                      src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/file-upload-v2.svg"
                      alt="file-upload-img"
                    />
                    <Typography sx={{ mt: 2 }} variant="h6">
                      Click Here to Drop your Compliance Document
                    </Typography>
                    <Typography sx={{ mt: 1, color: 'gray' }}>
                      Uploaded documents are private and can only be seen by you
                    </Typography>
                  </Box>
                </Button>
              </label>
            </Box>
            {selectedFile ? (
              <>
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6">Uploaded File</Typography>
                  <Card>
                    <CardContent className="flex justify-between align-center">
                      <Box className="flex align-center">
                        <FileTypeImage3D name={selectedFile.name} customWidth={'500px'} />
                        <Typography sx={{ ml: 1 }}>{formatString(selectedFile.name, 20)}</Typography>
                      </Box>
                      <Box>
                        <IconButton onClick={handleRemove}>
                          <CancelIcon />
                        </IconButton>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
