import axios from 'axios';
import { Auth } from 'aws-amplify';
import { GET_COMPANY_DOCUMENTS } from '../../types';
import { GET_DOCUMENT_FROM_EXTERNAL_DB_API, GET_POLICY_REQUIREMENTS } from '../../API';

export const getRequirementsFromComplianceDoc = (foundChunks, totalOutputLengthFromOpenAI) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;

  try {
    const res = await axios.get(GET_POLICY_REQUIREMENTS, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        email,
      },
    });
    if (res) {
      console.log('documents', res);
      //   dispatch({
      //     type: GET_COMPANY_DOCUMENTS,
      //     payload: res.data,
      //   });
    }
  } catch (err) {
    dispatch({
      type: GET_COMPANY_DOCUMENTS,
      payload: [],
    });
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
