import { Helmet } from 'react-helmet-async';
// @mui
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Typography, useTheme } from '@mui/material';
import { withNoAuth } from '../components/HOC/withNoAuth';
// hooks
import useResponsive from '../hooks/useResponsive';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const isDesktop = useResponsive('up', 'md');
  return (
    <>
      <Helmet>
        <title>Biosimilars AI Login </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection
            style={{
              boxShadow: 'none',
            }}
          >
            <Link to="/">
              <img
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/biologics-ai/BiosimilarsAI.png"
                alt="Logo"
              />
            </Link>
            <Typography variant="h3" sx={{ px: 5, mt: 3, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent style={{ minHeight: isDesktop ? '100vh' : '70vh' }}>
            {isDesktop ? (
              <></>
            ) : (
              <Link to="/">
                <img
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/biologics-ai/BiosimilarsAI.png"
                  alt="Logo"
                />
              </Link>
            )}
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

export default withNoAuth(LoginPage);
